import Creatable from "react-select/creatable"
import React, { useEffect, useState } from "react"
import Slider from "rc-slider"
import sortBy from "lodash/sortBy"
import toNumber from "lodash/toNumber"
import without from "lodash/without"
import { Badge, Button, Input, Spinner } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import Layout from "../components/layout"
import callFlask from "../callFlask"
import { Annotated } from "../hasura/slices/passage"
import { NavigableProgress } from "../components/common/progress"
import { NotificationId, setNotificationAction } from "../hasura/slices/notification"
import { Option } from "../interfaces/option"
import { QueryName } from "../hasura/queryNames"
import { imageFor, imagesQueueFor, passageTextualRepresentation, queryParams } from "../lib/helpers"
import { usePrevious } from "../hooks/usePrevious"
import { userSelector, UserState } from "../hasura/slices/user"

import {
  conceptSelector,
  ConceptState,
  deleteConceptImageAppearanceAction,
  fetchConceptsAction,
  insertConceptAndConceptImageAppearanceAction,
  insertConceptImageAppearanceAction,
} from "../hasura/slices/concept"

import {
  fetchImageAction,
  imageSelector,
  ImageState,
  updateAdminImagesQueueAction,
  updateConceptImageAppearancePrimaryAction,
  updateImageAction,
} from "../hasura/slices/image"

// @ts-ignore
import deleteIcon from "../lib/images/delete.svg"
// @ts-ignore
import rotateIcon from "../lib/images/rotate.svg"

export default function ImageComponent() {
  const dispatch = useDispatch()

  const { accessToken, user }: UserState = useSelector(userSelector)
  const { image, isQuerying }: ImageState = useSelector(imageSelector)
  const { concepts }: ConceptState = useSelector(conceptSelector)

  const [rotation, setRotation] = useState(0)
  const [ids, setIds] = useState<number[] | undefined>([])
  const [idx, setIdx] = useState<number | undefined>(0)
  const [caption, setCaption] = useState<string | null>()
  const [quality, setQuality] = useState<number | null>()

  const isInQueue = (ids || []).length > 0
  const imageUrl = image ? imageFor(image.s3_path) : undefined

  const isLoading = isQuerying[QueryName.FetchImage] || isQuerying[QueryName.UpdateAdminImagesQueue]

  const previousImage = usePrevious(image)

  useEffect(() => {
    if (!ids?.length || idx === undefined) return

    setRotation(0)
    dispatch(fetchImageAction(accessToken!, ids[idx]))
  }, [ids, idx])

  useEffect(() => {
    if (concepts?.length || !accessToken) return

    dispatch(fetchConceptsAction(accessToken))
  }, [accessToken])

  useEffect(() => {
    if (!accessToken) return

    fetchImage()
  }, [accessToken])

  useEffect(() => {
    if (!image || image.id === previousImage?.id) return

    setCaption(image.caption)
    setQuality(image.quality)
  }, [image, previousImage])

  const fetchImage = () => {
    const { id, ids } = queryParams()

    if (ids) {
      setIds(ids.split(",").map(toNumber))
    }

    if (id) {
      dispatch(fetchImageAction(accessToken!, parseInt(id)))
    }
  }

  const handleSave = async () => {
    if (rotation !== 0) {
      callFlask(`/image/rotate`, "PUT", { url: imageUrl, rotation })
    }

    dispatch(updateImageAction(accessToken!, image!.id, caption || null, quality || null))

    if (isInQueue) {
      const updated = without(imagesQueueFor(user), ids![idx!])
        .filter((s) => s)
        .join(",")
      await dispatch(updateAdminImagesQueueAction(accessToken!, user!.id, updated))
      handleSkip()
    }

    dispatch(setNotificationAction(NotificationId.ImageSaved))
  }

  const updateConceptImageAppearancePrimary = async (id: number, primary: boolean) => {
    await dispatch(updateConceptImageAppearancePrimaryAction(accessToken!, id, primary))
    fetchImage()
  }

  const deleteConceptImageAppearance = async (id: number) => {
    await dispatch(deleteConceptImageAppearanceAction(accessToken!, id))
    fetchImage()
  }

  const insertConceptImageAppearance = async (imageId: number, conceptId: number) => {
    await dispatch(insertConceptImageAppearanceAction(accessToken!, imageId, conceptId))
    fetchImage()
  }

  const insertConceptAndConceptImageAppearance = async (imageId: number, conceptDisplayName: string) => {
    await dispatch(insertConceptAndConceptImageAppearanceAction(accessToken!, imageId, conceptDisplayName))
    fetchImage()
  }

  const handleSkip = () => {
    if (ids![idx! + 1]) {
      setIdx(idx! + 1)
    } else {
      navigate("/home")
    }
  }

  const handleBack = () => {
    setIdx(idx! - 1)
  }

  const conceptOptions: Option[] = sortBy(
    (concepts || []).map((c) => ({ value: c.id, label: c.display_name })),
    (c) => c.label.toLowerCase()
  )

  return (
    <Layout>
      {image ? (
        <div>
          <div style={{ paddingBottom: isInQueue ? "200px" : 0 }} className="mt-4 mx-auto max-width-1100px">
            <div className="d-flex align-items-start">
              <div className="mx-auto position-relative max-width-500px flex-grow">
                <div style={{ height: "500px" }} className="w-100 mx-auto border flex-center position-relative">
                  <img className="mw-100 mh-100 w-auto h-auto" style={{ transform: `rotate(${rotation}deg)` }} src={imageUrl} />
                </div>

                <div className="d-flex justify-content-between">
                  <p className="text-xs text--gray8 mb-0 min-width-100px">ID {image.id}</p>

                  <p className="text-xs text--gray8 mb-0 single-line">{image.source}</p>
                </div>

                <div onClick={() => setRotation(rotation === 270 ? 0 : rotation + 90)} className="d-flex mt-2 pointer">
                  <p className="text-xs text--gray8 mr-1 my-0">Rotate</p>

                  <img className="icon-xs" src={rotateIcon} />
                </div>
              </div>

              <div style={{ flex: 1 }} className="flex-even ml-5">
                <div className="mb-5">
                  <h1 className="garamond text-l mb-1">Concepts & passages</h1>

                  <div className="mb-4">
                    <div className="mb-2">
                      {sortBy(image.concept_image_appearances, "concept.display_name").map((c, idx) => (
                        <div key={idx}>
                          <div className="m-0 d-flex align-items-center mb-1">
                            <p className="min-width-80px text-s mb-0">{c.concept.display_name}</p>

                            <p
                              onClick={() => updateConceptImageAppearancePrimary(c.id, !c.primary)}
                              className={`pointer semibold min-width-150px px-2 mb-0 text--${c.primary ? "primary" : "gray5"} text-s`}
                            >
                              {c.primary ? "PRIMARY" : "SECONDARY"}
                            </p>

                            <img onClick={() => deleteConceptImageAppearance(c.id)} className="svg-icon hover" src={deleteIcon} />
                          </div>
                        </div>
                      ))}
                    </div>

                    <Creatable
                      className="width-250px"
                      onChange={(option) => insertConceptImageAppearance(image.id, option?.value)}
                      options={conceptOptions}
                      placeholder="Add concept"
                      onCreateOption={(displayName) => insertConceptAndConceptImageAppearance(image.id, displayName)}
                    />

                    {image.search_term && (
                      <div className="text-s">
                        search term was
                        <Badge className="text-xs ml-2" color="dark">
                          {image.search_term}
                        </Badge>
                      </div>
                    )}
                  </div>

                  {image.illustrated_passages.length > 0 && (
                    <div>
                      {image.illustrated_passages
                        .filter((i) => i.passage.annotated)
                        .map((i, idx) => (
                          <p key={idx} className="text-s">
                            {passageTextualRepresentation(i.passage.annotated as Annotated)}
                          </p>
                        ))}
                    </div>
                  )}
                </div>

                <div>
                  <h1 className="garamond text-l mb-1">Metadata</h1>

                  <div className="mb-4">
                    <Input
                      autoFocus
                      onChange={(e: any) => setCaption(e.target.value)}
                      type="textarea"
                      className="data-hj-allow min-height-150px max-width-400px"
                      placeholder="Caption..."
                      value={caption || ""}
                    />
                  </div>

                  <div className="d-flex align-items-start">
                    <p className="text-xxs text--gray7 m-0 bold mr-3">QUALITY</p>

                    <Slider
                      className="mb-4 max-width-300px pointer"
                      // @ts-ignore
                      onChange={(quality) => setQuality(quality)}
                      value={quality || undefined}
                      min={1}
                      max={10}
                      marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {!isInQueue && (
              <Button size="lg" className="mt-4 min-width-200px float-right" onClick={handleSave} color="success">
                Save
              </Button>
            )}
          </div>
        </div>
      ) : (
        <Spinner color="primary" />
      )}

      {isInQueue && (
        <NavigableProgress
          idx={idx!}
          count={ids!.length}
          isLoading={isLoading}
          handleSave={handleSave}
          handleSkip={handleSkip}
          handleBack={handleBack}
          backDisabled={idx === 0 || isQuerying[QueryName.FetchImage]}
          nextDisabled={isQuerying[QueryName.FetchImage]}
        />
      )}
    </Layout>
  )
}
